import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import team1 from '../assets/80.png'
import team2 from '../assets/5063.png'
import team3 from '../assets/5656.png'
import team4 from '../assets/9910.png'

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Team extends Component {

    render() {
        return (

            <div class="boxWrapTeam">

                    <div class="about2">
                        <div class="conT2">TEAM</div>
                        <div class="conTSub">Drawing on our expertise in digital art and blockchain, as well as our experience in community engagement and marketing, we bring a wealth of experience and creativity to each step of the process. From the initial concept to the complex design of every NFT, they work together seamlessly to create a collection that tells a story that goes beyond digital art while providing utility from day 1.</div>
                        <div class="teamSection"  data-aos="fade-in" data-aos-duration="1000">
                            <div class="box1">
                                <img src={team1}/>
                                <div class="name">MEGGA</div>
                                <div class="name2">Creator</div>
                            </div>

                            <div class="box1">
                                <img src={team2}/>
                                <div class="name">BUBE MILES</div>
                                <div class="name2">Head of Strategy</div>
                            </div>
                       
                         </div>
                         
                          <div class="teamSection"  data-aos="fade-in" data-aos-duration="1000">
                            <div class="box1">
                                <img src={team3}/>
                                <div class="name">DAVID</div>
                                <div class="name2">Social Media Manager</div>
                            </div>

                            <div class="box1">
                                <img src={team4}/>
                                <div class="name">SCOOBY</div>
                                <div class="name2">Community Manager</div>
                            </div>
                       
                         </div>
 
                    </div>
             </div>
        )
    }
}

export default Team;

