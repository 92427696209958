import React, { Component } from 'react';

class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqBg">

        <div class="conF">FREQUENTLY ASKED QUESTIONS </div>

        <div class="accordin reveal">

          <details data-aos="fade-up" data-aos-duration="1000">
            <summary class="summary1">What is NFT?</summary>
            <div class="faq__content">
              <p>"Non-fungible token" is referred to as NFT. Non-fungible objects are distinct and cannot be substituted by another object; there are no two or more NFTs that are alike. Digital assets including digital artwork, 3D, GIFs, pictures, movies, audio, and more are easily verifiable.</p>
            </div>
          </details>
          <details data-aos="fade-up" data-aos-duration="1000">
            <summary class="summary1">Why would I want to own an NFT?</summary>
            <div class="faq__content">
              <p>1. For its rarity, gives you entry to perks and advantages available exclusively to members.</p>
              <p>2. For the pleasure of amassing art. They may stand in for valuable, collectible digital artwork that we can show off and value.</p>
            </div>
          </details>
          <details data-aos="fade-up" data-aos-duration="1000">
            <summary class="summary1">What is the "Frenchie Royale" NFT Collection?</summary>
            <div class="faq__content">
              <p>On the Ethereum blockchain, the Frenchie Royale is a collection of 10,000 unique Frenchie NFTs. Since the dawn of humanity, dogs have been our partners. As equal partners in this new digital universe, we bring our actual pets. Together, we open up countless potential in Web3 and the Metaverse. Each NFT is a masterpiece that not only showcases these aristocratic canines but also grants collectors a complimentary vacation experience to iconic destinations.</p>
            </div>
          </details>

          <details data-aos="fade-up" data-aos-duration="1000">
            <summary class="summary1">Which blockchain are you built on?</summary>
            <div class="faq__content">
              <p>Frenchie Royale is built on the Ethereum blockchain ERC-721.</p>
            </div>
          </details>
          
            <details data-aos="fade-up" data-aos-duration="1000">
            <summary class="summary1">What is the total supply?</summary>
            <div class="faq__content">
              <p>Frenchie Royale NFT has a total supply of 10,000 unique art French Bulldogs.
Note: 120 Frenchies will be withheld to be used as raffle prize, community giveaways & team/contributors reward.</p>
            </div>
          </details>
          
           <details data-aos="fade-up" data-aos-duration="1000">
            <summary class="summary1">How does the complimentary vacation experience work?</summary>
            <div class="faq__content">
              <p>With every purchase of "Frenchie Royale" NFT, you get access to an exclusive online portal called "THE EMBASSY". This portal allows you to virtually explore the collection's various destinations and choose your desired location to explore.</p>
            </div>
          </details>
          
          <details data-aos="fade-up" data-aos-duration="1000">
            <summary class="summary1">How to get "Frenchie Royale" NFTs?</summary>
            <div class="faq__content">
              <p>If you want to get NFTs from the "Frenchie Royale" collection, you can participate in our future sales events. Keep an eye on our official websites and social media channels for announcements of release dates and event details.</p>
            </div>
          </details>

        </div>

      </div>


    )
  }
}

export default FAQ;

